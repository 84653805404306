import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    .container {
        padding-top: 56px;
        padding-bottom: 100px;
    }

    .row p {
        font-family: 'Cardo', serif;
    }
      
    .container h5 {
        font-family: 'Cardo', serif;
        font-size: 105%;
    }
      
    .text-info {
        font-family: 'Cardo', serif;
        color: #21618C!important;
    }
      
    .row a {
        color: #21618C!important;
    }
      
    .source-date {
        font-size: 18px;
        font-family: 'Playfair Display', serif!important;
    }
`

class WritingItem extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { article } = this.props.location.state
        return (
            <Styles>
                <Container>
                    <Row>
                        <Col lg="2"></Col>
                        <Col lg="8">
                            <br />
                            {article.external !== '' ? (
                                <h3>
                                    <a className="text-info" href={article.external}>
                                        {article.title}
                                    </a>
                                </h3>
                            ) : (
                                    <h3 className="text-info">
                                        {article.title}
                                    </h3>
                                )}
                            <p className="source-date"><em>{article.publisher}, {article.date}</em></p>
                            <h5>By: Zach St. George</h5>
                            <hr />
                            {article.content.map(line => (
                                <React.Fragment>
                                    {/* <p>{line}</p> */}
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: line
                                        }}>
                                    </div>
                                </React.Fragment>
                            ))}
                            <hr />
                            <br />
                            <br />
                            <br />
                        </Col>
                    </Row>
                </Container>
            </Styles>
        )
    }
}

export default WritingItem;