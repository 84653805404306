import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    .main {
        padding-top: 100px;
        padding-bottom: 100px;
        font-family: 'Cardo', serif;
    }

    p {
        line-height: 160%;
        font-size: 18px;
    }

`
class Events extends React.Component {
    render() {
        return (
            <Styles>
                <Container className="main">
                    <h2>News</h2>
                    <hr />
                    <p><a href="https://www.youtube.com/watch?v=oG9Bjm_7s7o" target="_blank" rel="noopener noreferrer">Powell’s City of Books – In conversation with Robert Moor </a></p>
                    <p><a href="https://www.youtube.com/watch?v=XNWt_4BJfz4&feature=emb_title&utm_source=Bay+Nature&utm_campaign=6d9cbf8082-" target="_blank" rel="noopener noreferrer">Bay Nature Magazine and Point Reyes Books – In conversation with Victoria Schlesinger</a></p>
                    <p><a href="https://www.npr.org/2020/07/28/896030567/believe-it-or-not-forests-migrate-but-not-fast-enough-for-climate-change" target="_blank" rel="noopener noreferrer">NPR – All Things Considered – “Believe it or not, trees migrate, but not fast enough for climate change”</a></p>
                    <p><a href="https://omny.fm/shows/inquiring-minds/a-story-about-forests-people-and-the-future" target="_blank" rel="noopener noreferrer">Inquiring Minds Podcast – “A story about forests, people, and the future”</a></p>
                    <p><a href="https://think.kera.org/2020/07/23/meet-the-real-tree-huggers/" target="_blank" rel="noopener noreferrer">KERA – Think – “Meet the real tree huggers” </a></p>
                    <p><a href="https://www.publicradiotulsa.org/post/journeys-trees-story-about-forests-people-and-future" target="_blank" rel="noopener noreferrer">Public Radio Tulsa – 1A – “The Journeys of Trees”</a></p>
                    <p><a href="https://www.ijpr.org/show/the-jefferson-exchange/2020-06-22/tue-9-am-how-forest-migrate-in-the-journeys-of-trees" target="_blank" rel="noopener noreferrer">Jefferson Public Radio – The Jefferson Exchange – “How forests migrate” </a></p>
                    <p><a href="https://www.kalw.org/post/one-planet-impact-climate-change-trees-and-forests#stream/0" target="_blank" rel="noopener noreferrer">KALW – Your Call – “The impact of climate change on trees and forests”</a></p>

                </Container>
            </Styles>
        )
    }
}

export default Events;
