import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    nav a {
        font-family: 'Cardo', serif;
    }

    .navbar {
        // background-color: #222;
    }

    .navbar-brand, .navbar-nav, .nav-link, .nav-item {
        // color #bbb !important;
    }
`
//https://stackoverflow.com/questions/42253277/react-router-v4-how-to-get-current-route

export const NavigationBar = () => (
    <Styles>
        <Navbar expand="lg" bg="dark" variant="dark" fixed="top">
            <Container>
                <Navbar.Brand href="/">ZACH ST. GEORGE / REPORTER</Navbar.Brand>
                {/* Zach St. George / Reporter / Author of The Journeys of Trees */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/about">About</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/events">News & Events</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/writing">Writing</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/contact">Contact</Nav.Link></Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </Styles>
)
