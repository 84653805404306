import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
    freelanceArticles,
    natilusArticles,
    arizonaRepublicArticles,
    richmondConfidentialArticles,
    northCoastArticles,
} from './data';

const Styles = styled.div`
    .container {
        padding-top: 80px;
        padding-bottom: 100px;
    }

    .source-date {
        font-size: 16px;
        font-family: 'Playfair Display', serif;
    }
    
    .lead {
        font-family: 'Cardo', serif;
        font-size: 18px;
    }
    
    .display-4 {
        font-size: 36px;
        font-family: 'Cardo', serif;
    }
    
    .article-link {
        color: #21618C!important;
        font-family: 'Cardo', serif;
    }
`
class Writing extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         articles: [],
    //     }
    // }

    // async onListArticles() {
    //     const response = await axios.get('url', {
    //         headers: {
    //             'Content-Type': ,
    //             'accept': 'application/json'
    //         }
    //     })
    // this.setState({
    //     artiles: (response.data) ? response.data : [],
    //     isLoaded: true
    // })
    // }

    // componentDidMount() {
    //     this.onListArticles;
    //     this.interval = setInterval() ...
    // }

    // componentWillUnmount() {
    //     clearInterval(this.interval);
    // }

    render() {
        // var { articles } = this.state;

        return (
            <Styles>
                <Container>
                    <br />
                    <WritingListItem title="Freelance" data={freelanceArticles}></WritingListItem>
                    <WritingListItem title="Nautilus Magazine" data={natilusArticles}></WritingListItem>
                    <WritingListItem title="The Arizona Republic" data={arizonaRepublicArticles}></WritingListItem>
                    <WritingListItem title="Richmond Confidential" data={richmondConfidentialArticles}></WritingListItem>
                    <WritingListItem title="The North Coast Journal" data={northCoastArticles}></WritingListItem>
                </Container>
            </Styles>
        )
    }
}

class WritingListItem extends React.Component {
    render() {
        return (
            <Row>
                <Col lg="2"></Col>
                <Col lg="8">
                    {this.props.data.map(article => (
                        <React.Fragment>
                            <h4 key={article.title}>
                                <Link className="article-link" to={{ pathname: `/writing/${article.title.replace(/ /g, "-")}`, state: { article: article } }}>
                                    {article.title}
                                </Link>
                            </h4>
                            <p className="source-date"><em>{article.publisher}, {article.date}</em></p>
                            <hr />
                        </React.Fragment>
                    ))}
                </Col>
            </Row>
        )
    }
}

export default Writing;
