import React from 'react';
import { Container,  } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;
        line-height: 60px;
        background-color: #f5f5f5;
    }
`

export const Footer = () => (
    <Styles>
        <footer className="footer bg-dark">
            <Container>
                <p className="m-0 text-center text-white">Copyright &copy; zachstgeorge.com 2021</p>
            </Container>
        </footer>
    </Styles>
)
