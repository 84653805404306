import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

import book from '../assets/Journey-of-Trees-2.jpg';

const Styles = styled.div`
    .main {
        padding-top: 100px;
        padding-bottom: 100px;
        font-family: 'Cardo', serif;
    }

    img {
        margin: 30px 0px 15px 0px;
        height: 500px;
        width: auto;
        box-shadow:         10px 10px 10px  #ccc;
        display: block;
        border-radius: 5px;
    }

    .caption {
        line-height: 160%;
        font-size: 18px;
    }

    .caption-indent {
        line-height: 160%;
        font-size: 18px;
        padding-left: 20px;
    }

    .praise {
        text-align: center;
        padding: 20px 0px 20px 0px;
        font-size: 18px;
    }

    .intl {
        text-align: center;
        padding-top: 40px;
    }

    .order {
        text-align: center;
        padding: 20px 0px 20px 0px;
    }

    .center-align {
        text-align: center;
    }

    a {
        color: #21618C!important;
    }

    .seller-item {
        padding: 5px;
        text-align: center;
    }

    .btn {
        width: 160px
    }

    .spaced {
        line-height: 180%;
    }

    .btn-outline {
        color: #21618C!important;
        border-color: #21618C!important;
    }

    .btn-outline {
        color: #21618C!important;
        border-color: #21618C!important;
    }

    .btn-primary:active, .btn-primary:focus, .btn-outline:hover {
        color: #fff!important;
        background-color: #21618C!important;
        border-color: #21618C!important;

    }

`

class Home extends React.Component {
    render() {
        return (
            <Styles>
                <Container className="main">
                    <Row className="justify-content-md-center">
                        <Col lg="9">
                            <h1 className="center-align">The Journeys Of Trees</h1>
                            <img className="mx-auto" src={book} alt="" />
                            <br />
                            <Row className="justify-content-md-center">
                                <Col lg="11">
                                    <p className="caption">Once a tree is rooted in one place, it’s rare for it to wind up in another. Forests, though, are restless things. Anytime a tree dies or sprouts, the forest it is a part of has shifted a little. Through the fossils that ancient forests left behind, scientists can track their movements over the eons. They shuffle back and forth across continents, sometimes following the same route more than once.</p>
                                    <p className="caption">Today, though, the climate changes faster than ever before. For decades, scientists have worried that trees and other slow-moving species might not be able to keep up. Some of these scientists have suggested that people should help those species move to places where they’ll be better suited. Others, though, have urged caution—people have spent millennia moving living things from one part of the world to another, often with disastrous results.</p>
                                    <p className="caption">In <em>The Journeys of Trees</em>, science reporter Zach St. George tells the stories of five species of trees, and through them, the stories of people studying the forests of the past, protecting the forests of the present, and planting the forests of the future.</p>
                                </Col>
                            </Row>
                            <hr />
                            <h3 className="order">Praise for <em>The Journeys Of Trees</em></h3>
                            <Row className="justify-content-md-center">
                                <Col lg="11">
                                    <div className="caption">“A thoughtful and timely meditation on how our changing world disrupts the bonds between trees, thelands they root in, and the people whose lives they nourish―and what we might do about it.”</div>
                                    <div className="caption-indent">- Sonia Shah, author of <em>Pandemic</em> and <em>The Next Great Migration</em></div>
                                    <br />
                                    <div className="caption">“These stories vividly evoke the deep bonds between people and trees. In the paradoxical, surprisingjourneys of trees is writ the sylvan future of forests and cities alike.”</div>
                                    <div className="caption-indent">- David George Haskell, author of <em>The Forest Unseen</em></div>
                                    <br />
                                    <div className="caption">“A fascinating, eloquent, and often surprisingly funny journey into the troubled relationship betweenpeople and trees in a future that is now. This is the smartest book yet on how to think and live in the ageof climate change.”</div>
                                    <div className="caption-indent">- J. B. MacKinnon, author of <em>The Once and Future World</em></div>
                                    <br />
                                    <div className="caption">“How does a tree travel? From that seemingly koan-like question, Zach St. George creates anilluminating, enchanting, and occasionally alarming portrait of an earth in flux. I will treasure this book longinto our uncertain future.”</div>
                                    <div className="caption-indent">- Robert Moor, author of <em>On Trails</em></div>
                                    <br />
                                    <div className="caption">“This captivating book makes deep acquaintance with five species of tree, revealing that life is constantlyon the move and nature needs space to survive. Forests, it turns out, migrate across vast dimensions ofspace and time, engaged in an invisible ballet Zach St. George helps us to see.”</div>
                                    <div className="caption-indent">- Mary Ellen Hannibal, author of <em>Citizen Scientist</em></div>
                                    <br />
                                    <div className="caption">“An elegant―and urgent―paean to trees.”</div>
                                    <div className="caption-indent">- Jim Robbins, author of <em>The Man Who Planted Trees</em></div>
                                    <br />
                                    <div className="caption">“I was once asked by a scientist, ‘Are you ready to put a sprinkler system on the giant sequoias?’ ZachSt. George takes us on a journey to understand why this question is so important and the answer socomplex.”</div>
                                    <div className="caption-indent">- Jonathan Jarvis, former director of the National Park Service and coauthor of <em>The Future of Conservation in America</em></div>
                                    <br />
                                </Col>
                            </Row>
                            <hr />
                            <h3 className="order">Reviews</h3>
                            <Row className="justify-content-md-center">
                                <Col lg="11">
                                    <div className="caption">“A deeply researched book that is liable to change your perspective on the magnificent, tall, woody creatures that cover one-third of the Earth's land... [St. George] brings a flair for gathering and distilling esoteric scientific findings into lively, accessible explanations.”</div>
                                    <div className="caption-indent">- <a href="https://www.wsj.com/articles/the-journeys-of-trees-review-giants-in-transit-11602256237">The Wall Street Journal</a></div>
                                    <br />
                                    <div className="caption">“[A] fascinating sylvan road trip.”</div>
                                    <div className="caption-indent">- <a href="https://inquisitivebiologist.com/2020/09/15/book-review-the-journeys-of-trees-a-story-about-forests-people-and-the-future/#more-11377">The Inquisitive Biologist</a></div>
                                    <br />
                                    <div className="caption">“St. George embeds himself with ecologists throughout North America, giving us mind-blowing insights into how forests are supposed to move—and why many can’t. Like Bill Bryson, St. George is a curious everyman.”</div>
                                    <div className="caption-indent">- Apple Books</div>
                                    <br />
                                    <div className="caption">“[A] rumination, backed by in-depth reporting, on the current state of North America’s forests.…the extraordinary scientists [St. George] features here do give hope that their nuanced work…will be taken seriously enough to repair, even avert, catastrophic change.”</div>
                                    <div className="caption-indent">- Booklist</div>
                                    <br />
                                    <div className="caption">“A beautiful elegy to trees and the people working to preserve them. This compelling read shows how climate change impacts the natural ranges of tree species and how scientists are creating strategies to mitigate this influence.”</div>
                                    <div className="caption-indent">- Library Journal</div>
                                    <br />
                                </Col>
                            </Row>
                            <hr />
                            <h3 className="order">Order now from</h3>
                            <Row className="justify-content-md-center">
                                <Col lg="3">
                                    <SellerItem seller="Amazon" link="https://www.amazon.com/dp/1324001607/ref=cm_sw_su_dp" />
                                    <SellerItem seller="Apple Books" link="https://books.apple.com/us/book/journeys-trees-story-about-forests-people-future/id1486020058" />
                                </Col>
                                <Col lg="3">
                                    <SellerItem seller="Barnes and Noble" link="https://www.barnesandnoble.com/w/the-journeys-of-trees-zachary-st-george/1133534057;jsessionid=12FE9C5FA657638B47C9C3A5085A6918.prodny_store02-atgap06?ean=9781324001607" />
                                    <SellerItem seller="Books-A-Million" link="https://www.booksamillion.com/p/9781324001607" />
                                </Col>
                                <Col lg="3">
                                    <SellerItem seller="Powell’s Books" link="https://www.powells.com/book/-9781324001607" />
                                    <SellerItem seller="Indiebound" link="https://www.indiebound.org/book/9781324001607" />
                                </Col>
                            </Row>
                            <h3 className="intl">Available internationally from your local bookseller</h3>
                            <br />
                            <br />
                            <br />
                        </Col>
                    </Row>
                </Container>
            </Styles>
        )
    }
}

class SellerItem extends React.Component {
    render() {
        return (
            <div className="seller-item">
                <a href={this.props.link}>
                    <Button variant="outline">
                        {this.props.seller}
                    </Button>
                </a>
            </div>
        )
    }
}

export default Home;