import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

// Components
import { NavigationBar } from './components/NavigationBar';
import { Contact } from './components/Contact';
import { Layout } from './components/Layout';
import { Footer } from './components/Footer';
import Home from './components/Home';
import Writing from './components/Writing';
import Events from './components/Events';
import WritingItem from './components/WritingItem';
import About from './components/About';

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <NavigationBar />
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/writing" component={Writing} />
              <Route exact path="/events" component={Events} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/the-journeys-of-trees" component={Contact} />
              <Route exact path="/writing/:title" component={WritingItem} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Layout>
        </Router>
        <Footer />
      </React.Fragment>

    );
  }
}

export default App;
