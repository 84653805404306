import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import profile from '../assets/Zach01.jpg';

const Styles = styled.div`
    .container {
        padding-top: 120px;
        padding-bottom: 100px;
        font-family: 'Cardo', serif;
    }

    .caption {
        // text-align: justify;
        line-height: 200%;
        font-size: 18px;
    }

    img {
        height: 400px;
        margin: auto;    
        display: block;
    }

    .credit {
        text-align:center;
        font-size: 12px;
        padding: 5px;
    }

`

class About extends React.Component {
    render() {
        return (
            <Styles>
                <Container>
                    <Row my="5">
                        <Col lg="6">
                            <img className="img-fluid rounded mb-4 mb-lg-0" src={profile} alt="" />
                            <p class="credit">(Credit: Sean Havey)</p>
                        </Col>
                        <Col lg="5">
                            {/* <h1 className="">About</h1>
                            <br /> */}
                            <p className="caption">Zach St. George is a freelance reporter focused on climate change and conservation. His writing has been published by <em>National Geographic</em>, <em>The New York Times Magazine</em>, <em>Harper's</em>, <em>The Atlantic</em>, <em>Outside</em>, <em>Businessweek</em>, <em>Mother Jones</em>, <em>Grist</em>, <em>Smithsonian</em>, <em>Pacific Standard</em>, <em>Yale E360</em>, <em>Scientific American</em>, <em>USA Today</em>, and many others. He lives in Baltimore, Maryland.</p>
                        </Col>
                    </Row>
                </Container>
            </Styles>
        )
    }
}

export default About;
