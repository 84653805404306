import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import styled from 'styled-components';

const Styles = styled.div`
    header {
        padding: 100px 0 100px;
    }

    #contact-info i {
        padding-top: 10px;
    }

    .row a {
        color: #21618C!important;
    }

    section {
        padding: 130px 0;
        font-family: 'Cardo', serif;
    }
    
    .fa-twitter {
        color: #333;
    }

    .pd-l {
        padding-left: 40px;
    }

    h5 {
        padding-bottom: 8px;
    }
`

export const Contact = () => (
    <Styles>
        <section id="contact-info">
            <Container>
                <Row>
                    <Col lg="8" mx="auto">
                        {/* <h1 className="">Contact</h1>
                        <br /> */}
                        <p className="lead">Represented by Diana Finch:</p>
                        <h5 className="pd-l"><a href="mailto:dianafinchagent@gmail.com">dianafinchagent@gmail.com</a></h5>
                        <br />
                        <p className="lead">For book publicity and media inquiries contact:</p>
                        <h5 className="pd-l">Elizabeth Riley</h5>
                        <h5 className="pd-l">Senior Director of Publicity at W.W. Norton</h5>
                        <h5 className="pd-l"><a href="mailto:eriley@wwnorton.com">eriley@wwnorton.com</a></h5>
                        <br />
                        <p className="lead">For story tips, comments, phone number, routing number, etc., write to:</p>
                        <h5 className="pd-l"><a href="mailto:zach@zachstgeorge.com">zach@zachstgeorge.com</a></h5>
                        <a className="pd-l" href="https://twitter.com/ZachStGeorge">
                            <FontAwesomeIcon icon={faTwitter} size="2x" variant="dark" />
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* <Navbar expand="lg" bg="dark" variant="dark" fixed="top">
            <Container>
                <Navbar.Brand href="/">ZACH ST. GEORGE / REPORTER</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/about">About</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/writing">Writing</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/contact">Contact</Nav.Link></Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar> */}
    </Styles>
)
